import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import gbFlag from "assets/img/flags/gb.svg";
import esFlag from "assets/img/flags/es.svg";

const Book = ({ bookInfo }) => {
    const navigate = useNavigate();
    const [urlImageExists, setUrlImageExists] = useState(false);
    const [localImageExists, setLocalImageExists] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [isbn, setIsbn] = useState("");
    const [isMounted, setIsMounted] = useState(true); // Track component's mounted state

    useEffect(() => {
        setUrlImageExists(false);
        setLocalImageExists(false);
        if (bookInfo.isbn !== "") {
            setIsbn(bookInfo.isbn);
        } else {
            setIsbn(bookInfo.isbn13);
        }

        // Cleanup function to set isMounted to false when unmounting
        return () => {
            setIsMounted(false);
        };
    }, [bookInfo]);

    useEffect(() => {
        if (isbn !== "") {
            setImageUrl(`https://server.nadaqueleer.com/api/getImage/${isbn}.webp`);
        }
    }, [isbn]);

    useEffect(() => {
        // Fetch the image with error handling
        fetch(imageUrl)
            .then((response) => {
                if (response.status === 404) {
                    // If the image is not found (404), check if a local image exists
                    checkImageExists(isbn)
                        .then((exists) => {
                            if (isMounted) {
                                setLocalImageExists(exists);
                            }
                        })
                        .catch((error) => {
                            if (isMounted) {
                                setLocalImageExists(false);
                            }
                        });
                    if (isMounted) {
                        setUrlImageExists(false);
                    }
                } else {
                    // If the image is found, set the image url
                    if (isMounted) {
                        setUrlImageExists(true);
                    }
                }
            })
            .catch((error) => {
                // Handle errors here, similarly to the 404 case
            });
    }, [imageUrl, isbn, isMounted]);

    const checkImageExists = (isbn) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => reject(false);
            img.src = require(`assets/books/covers/${isbn}.webp`);
        });
    };

    return (
        <div className="w-full md:w-6/12 xl:w-4/12 mb-32 px-4">
            <div className="flex-auto px-5 lg:px-10">
                <div className="relative cursor-pointer">
                    <a
                        href={bookInfo.link ? bookInfo.link : bookInfo.link_en}
                        target="_blank"
                        className="relative flex flex-col min-w-0 break-words w-full mb-6 cursor-pointer"
                    >
                        <img
                            alt="..."
                            src={urlImageExists ?
                                imageUrl :
                                localImageExists ?
                                    require(`assets/books/covers/${isbn}.webp`) :
                                    require(`assets/books/covers/blank_cover.webp`)
                            }
                            className="w-full mb-6 shadow-lg hover:shadow-xl rounded-lg mx-auto aspect-10/15 object-cover object-center"
                        />
                    </a>
                    {false &&
                        <div className="flex mb-5 -space-x-4 absolute -translate-y-1/2 -translate-x-1/4 top-0">
                            {bookInfo.recommendedBy.map((recommender, index) => (
                                <>
                                    <img
                                        key={index}
                                        className={`w-12 h-12 rounded-full border-2 border-white`}
                                        src={require(`assets/img/${recommender}.webp`)} // Replace with the actual path to the profile photos
                                        alt={`Recomendado por ${recommender}`}
                                    />
                                </>
                            ))}
                        </div>
                    }
                </div>
                <div className="text-center text-sm items-center leading-normal text-blueGray-400 mb-2">
                    <a
                        href={`/${bookInfo.podcastId}`}
                    >
                        <i className="fas fa-podcast mr-1"></i>{" "}
                        <span className="font-semibold mr-4">{bookInfo.podcast}</span>
                        <i className="fas fa-bookmark mr-1"></i>{" "}
                        <span className="text-sm">{bookInfo.episode}</span>
                    </a>
                </div>
                <div className="min-h-16 md:min-h-24 no-select">
                    <a
                        href={bookInfo.link ? bookInfo.link : bookInfo.link_en}
                        target="_blank"
                        className="relative flex flex-col min-w-0 break-words w-full cursor-pointer"
                    >
                        <h4 className="text-2xl font-semibold text-center no-select">
                            {bookInfo.title}
                        </h4>
                        <p className="leading-relaxed mt-1 text-blueGray-500 text-center no-select">
                            {bookInfo.author}
                        </p>
                    </a>
                </div>
            </div>
            <div className="flex flex-wrap justify-center">
                {bookInfo.link &&
                    <div className="text-center mt-6 mx-2">
                        <a
                            href={bookInfo.link}
                            target="_blank"
                            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-400 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400"
                        >
                            <span className="relative uppercase inline-flex items-center px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                Lo quiero
                                <img
                                    src={esFlag}
                                    alt="Spanish Flag"
                                    className="w-6 h-6 ml-4 rounded-full"
                                />
                            </span>
                        </a>
                    </div>
                }
                {bookInfo.link_en && (
                    <div className="text-center mt-6 mx-2">
                        <a
                            href={bookInfo.link_en}
                            target="_blank"
                            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-pink-500 to-blue-600 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400"
                        >
                            <span className="relative uppercase inline-flex items-center px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                Lo quiero
                                <img
                                    src={gbFlag}
                                    alt="British Flag"
                                    className="w-6 h-6 ml-4 rounded-full"
                                />
                            </span>
                        </a>
                    </div>
                )}
            </div>
        </div >
    );
};

export default Book;