import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NewsletterService from "services/newsletter.service";
import PodcastDb from 'assets/podcasts/podcasts.json';
import booksService from "services/books.service";
import BookList from "components/Book/BookList.js";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

import PodcastList from "components/Podcast/PodcastList.js";

export default function Newsletter() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const unsubscribeQuery = queryParams.get('unsubscribe');
    const confirmationQuery = queryParams.get('confirm');
    const [unsubscribe, setUnsubscribe] = useState(false);
    const [filteredBooks, setFilteredBooks] = useState([]);
    const [filteredPodcasts, setFilteredPodcasts] = useState([]);
    const [bookDb, setBookDb] = useState([]);
    const [init, setInit] = useState(false);
    const [bookListTitle, setBookListTitle] = useState("📚 Libros añadidos recientemente");

    useEffect(() => {
        if (unsubscribeQuery === 'true') {
            setUnsubscribe(true);
        }
    }, [unsubscribeQuery]);

    useEffect(() => {
        booksService.getBooks()
            .then((response) => {
                if (response.status === 200) {
                    setBookDb(response.data);
                    setInit(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (init) {
            const filteredAndSortedBooks = bookDb
                .filter(item => item.isbn !== "" || item.isbn13 !== "")
                .sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;
                });
            setBookListTitle("📚 Libros añadidos recientemente");
            setFilteredBooks(filteredAndSortedBooks.slice(0, 12));
            const filteredAndSortedPodcasts = PodcastDb.map(podcast => {
                const latestBookDate = bookDb.reduce((latestDate, book) => {
                    if (book.podcastId === podcast.id) {
                        const bookDate = new Date(book.date);
                        return bookDate > latestDate ? bookDate : latestDate;
                    }
                    return latestDate;
                }, new Date(0)); // Initialize with a minimum date

                return { ...podcast, latestBookDate };
            })
                .sort((a, b) => {
                    const dateA = new Date(a.latestBookDate);
                    const dateB = new Date(b.latestBookDate);
                    return dateB - dateA;
                });

            setFilteredPodcasts(filteredAndSortedPodcasts);
        }
    }, [init]);

    const handleSub = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        // Access the input value by name
        const email = e.target.elements['email'].value;
        NewsletterService.newSub(email)
            .then(response => {
                const message = response.data.message;
                console.log(message);
            })
            .catch(error => {
                console.error(error);
            });
    };

    return (
        <>
            <Navbar transparent />
            <main>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-25">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover bg-sky-300"
                    >
                    </div>

                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-8/12 px-4 pt-16 ml-auto mr-auto text-center">
                                {unsubscribe ? (
                                    <>
                                        <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500">
                                            <span className="text-xl inline-block mr-5 align-middle">
                                                <i className="fas fa-check"></i>
                                            </span>
                                            <span className="inline-block align-middle mr-8">
                                                <b className="capitalize">
                                                    Baja hecha correctamente. Ya no recibirás más correos. </b>
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <section className="">
                                        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                                            <div className="mx-auto max-w-screen-md sm:text-center">
                                                <h2 className="mb-4 text-3xl tracking-tight font-semibold text-white sm:text-4xl dark:text-white">
                                                    Recibe los mejores libros en tu bandeja de entrada
                                                </h2>
                                                <p className="mx-auto mb-8 max-w-2xl font-light text-white md:mb-12 sm:text-xl dark:text-gray-400">
                                                    No te pierdas ninguna recomendación con nuestro newsletter semanal.
                                                </p>
                                                <div className="relative">
                                                    {confirmationQuery ? (
                                                        <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500">
                                                            <span className="text-xl inline-block mr-5 align-middle">
                                                                <i className="fas fa-check"></i>
                                                            </span>
                                                            <span className="inline-block align-middle mr-8">
                                                                <b className="capitalize">¡Ya estás suscrito al newsletter!</b>
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                                                <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                                                </svg>
                                                            </div>
                                                            <input
                                                                type="email"
                                                                id="email"
                                                                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                placeholder="Introduce tu email"
                                                                required
                                                            />
                                                            <button
                                                                type="submit"
                                                                className="w-auto flex items-center justify-center absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-sky-500 rounded-r-lg hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800"
                                                            >
                                                                Suscríbete
                                                            </button>
                                                        </>
                                                    )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )
                                }
                            </div>
                        </div>
                    </div>
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-white fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>
                </div>

            </main>
            {filteredBooks.length > 0 && (
                <section className="pt-20 pb-48">
                    <div className="flex flex-col items-center mb-16">
                        <h2 className="text-4xl font-semibold text-center">
                            {bookListTitle}
                        </h2>
                        <a href="/libros" className="mt-4 inline-flex items-center font-medium text-base text-sky-600 hover:text-sky-800 dark:text-sky-500 dark:hover:text-sky-700">
                            VER TODOS
                            <svg className="ml-1 w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        </a>
                    </div>
                    <BookList books={filteredBooks} />
                    <div className="flex justify-center">
                        <a
                            type="button"
                            href="/libros"
                            className="text-white text-lg bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-6 py-3.5 text-center">
                            MÁS LIBROS
                        </a>
                    </div>
                </section>
            )}
            <Footer />
        </>
    );
}