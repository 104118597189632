import api from "./api";

const URL_PREFIX = '/books/';

class BooksService {
  getBooks() {
    return api.get(URL_PREFIX + "getBooks");
  }
}

const booksServiceInstance = new BooksService();

export default booksServiceInstance;