import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';


import booksService from "services/books.service";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

import PodcastDb from 'assets/podcasts/podcasts.json';

import GroupedBooks from "components/Book/GroupedBooks.js";

export default function PodcastPage() {

  const { podcastId } = useParams();
  const [podcastData, setPodcastData] = useState(null);

  const [bookDb, setBookDb] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);

  useEffect(() => {
    booksService.getBooks()
      .then((response) => {
        if (response.status === 200) {
          setBookDb(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {

    const matchingPodcast = PodcastDb.find((podcast) => podcast.id === podcastId);

    if (matchingPodcast) {
      setPodcastData(matchingPodcast);
    } else {
      // Handle the case where the podcastId doesn't match any podcast in the list
      // You can navigate to a 404 page or display an error message.
    }
  }, [podcastId]);

  useEffect(() => {
    if (podcastData && bookDb.length > 0) {
      const filteredAndSortedBooks = bookDb
        .filter(item => item.podcastId === podcastData.id)
        .sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        });

      setFilteredBooks(filteredAndSortedBooks);
    }
  }, [podcastData, bookDb]);



  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-25">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-sky-300"
          >
          </div>

          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4 pt-16 ml-auto mr-auto text-center">
                {podcastData && (
                  <h1 className="text-white font-semibold text-5xl">
                    {podcastData.name}
                  </h1>
                )}
                <p className="mt-10 text-xl text-white">
                  {
                    //Nada Que Ganar, Kaizen, Heavy Mental y muchos más
                  }
                </p>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        {podcastData && (
          <div className="relative">
            <div className="flex justify-center -mt-24 ">
              <img
                alt="..."
                src={require(`assets/podcasts/${podcastData.id}.webp`)}
                className="aspect-1 shadow-xl h-auto align-middle border-none"
                style={{
                  borderRadius: '10%',
                  objectFit: 'cover',
                  maxWidth: '200px',
                  maxHeight: '200px'
                }}
              />
            </div>
          </div>
        )}

        {filteredBooks.length > 0 && (
          <section className="pt-20 pb-48">
            <GroupedBooks books={filteredBooks} />
          </section>
        )}

      </main>
      <Footer />
    </>
  );
}
