import React from 'react';

const Podcast = ({ podcastInfo }) => {

    return (
        <div className="w-6/12 md:w-4/12 xl:w-3/12 mb-12 px-4">
            <div className="flex-auto px-5 lg:px-10">
                <div className="relative cursor-pointer">
                    <a
                        href={`/${podcastInfo.id}`}
                        className="relative flex flex-col min-w-0 break-words w-full cursor-pointer"
                    >
                        <img
                            alt="..."
                            src={require(`assets/podcasts/${podcastInfo.id}.webp`)}
                            className="w-full mb-6 shadow-lg hover:shadow-xl rounded-lg mx-auto aspect-1 object-cover object-center"
                        />
                    </a>
                </div>
                <div className="no-select">
                    <a
                        href={`/${podcastInfo.id}`}
                        className="relative flex flex-col min-w-0 break-words w-full cursor-pointer"
                    >
                        <h4 className="text-2xl font-semibold text-white text-center no-select">
                            {podcastInfo.name}
                        </h4>
                    </a>
                </div>
            </div>
        </div >
    );
};

export default Podcast;