import React, { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from 'uuid';

// components

import Book from "components/Book/Book.js";

export default function GroupedBooks({ books }) {

    const episodesPerPage = 5;
    const [totalPages, setTotalPages] = useState(1);
    const [filteredBooks, setFilteredBooks] = useState(books);
    const [groupedBooks, setGroupedBooks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedEpisodes, setPaginatedEpisodes] = useState([]);
    const [key, setKey] = useState(uuidv4()); // Add a key that changes when books change


    useEffect(() => {
        // Calculate the start and end index for the current page
        const startIndex = (currentPage - 1) * episodesPerPage;
        const endIndex = startIndex + episodesPerPage;

        // Get the episodes for the current page
        const currentEpisodes = Object.entries(groupedBooks)
            .slice(startIndex, endIndex)
            .reduce((result, [episode, booksInEpisode]) => {
                result[episode] = booksInEpisode;
                return result;
            }, {});

        console.log(currentEpisodes)
        // Set the paginated episodes for rendering
        setPaginatedEpisodes(currentEpisodes);
    }, [groupedBooks, currentPage]);

    useEffect(() => {
        const bookGroups = filteredBooks.reduce((result, book) => {
            const { episode } = book;
            if (!result[episode]) {
                result[episode] = [];
            }
            result[episode].push(book);
            return result;
        }, {});
        setGroupedBooks(bookGroups);
    }, [filteredBooks]);

    useEffect(() => {
        setTotalPages(Math.ceil(Object.keys(groupedBooks).length / episodesPerPage));
    }, [groupedBooks]);

    useEffect(() => {
        // Update the key whenever books change to reset the component
        setKey(uuidv4());
    }, [paginatedEpisodes]);


    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <div key={key} className="container mx-auto px-4">
            {Object.entries(paginatedEpisodes).map(([episode, booksInEpisode], index) => (
                <div key={index}>
                    <h2 className="text-xl  font-bold">
                        {booksInEpisode[0].episode_title &&
                            '🎙 ' + booksInEpisode[0].episode_title
                        }
                    </h2>
                    <h2 className="text-lg mb-12">
                        {`Episodio ${episode} (${booksInEpisode.length} ${booksInEpisode.length === 1 ? 'libro' : 'libros'})`}
                    </h2>
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">
                            {booksInEpisode.map((book, bookIndex) => (
                                <Book key={bookIndex} bookInfo={book} />
                            ))}
                        </div>
                    </div>
                </div>
            ))}

            {totalPages > 1 &&
                <div className="mt-4">
                    <nav aria-label="Page navigation example">
                        <ul className="flex items-center justify-center -space-x-px h-10 text-base">
                            <li>
                                <a
                                    href="#"
                                    className="flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    <span className="sr-only">Previous</span>
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 6 10"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 1 1 5l4 4"
                                        />
                                    </svg>
                                </a>
                            </li>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <li key={i}>
                                    <a
                                        href="#"
                                        className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 ${i + 1 === currentPage
                                            ? "font-bold text-sky-700 bg-sky-100 border-sky-400 hover:text-sky-900 dark:border-gray-700 dark:bg-sky-700 dark:text-white"
                                            : "hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                            }`}
                                        onClick={() => handlePageChange(i + 1)}
                                    >
                                        {i + 1}
                                    </a>
                                </li>
                            ))}
                            <li>
                                <a
                                    href="#"
                                    className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark-bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    <span className="sr-only">Next</span>
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 6 10"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 9 4-4-4-4"
                                        />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            }
        </div>
    );
}