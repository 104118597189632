import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NewsletterService from "services/newsletter.service";

export default function Footer() {

  const [success, setSuccess] = useState(false);

  const handleSub = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Access the input value by name
    const email = e.target.elements['email'].value;
    NewsletterService.newSub(email)
      .then(response => {
        const message = response.data.message;
        setSuccess(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <>
      <footer className="relative bg-blueGray-200 pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold">
                Echas en falta algún podcast? <br /> Escríbenos!</h4>
              <div className="mt-6 lg:mb-0 mb-6">
                <a
                  href="https://x.com/NadaQueLeer_"
                  target="_blank"
                >
                  <button
                    className="bg-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-x-twitter"></i>
                  </button>
                </a>
                <a
                  href="mailto:hola@nadaqueleer.com"
                >
                  <button
                    href="mailto:hola@nadaqueleer.com"
                    className="bg-white text-sky-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="far fa-envelope"></i>
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <section className="">
                <div className="pt-2 px-4 mx-auto max-w-screen-xl lg:px-6">
                  <div className="mx-auto max-w-screen-md sm:text-center">
                    <p className="mx-auto max-w-2xl font-semibold mb-5 sm:text-xl text-right">
                      No te pierdas ninguna recomendación con nuestro newsletter semanal
                    </p>
                    <form onSubmit={handleSub}>
                      <label htmlFor="email" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Newsletter</label>
                      {success ? (
                        <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500">
                          <span className="text-xl inline-block mr-5 align-middle">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="inline-block align-middle">
                            <b className="capitalize">Comprueba tu bandeja de entrada y confirma tu suscripción</b>
                          </span>
                        </div>
                      ) : (
                        <>
                          <div className="relative">
                            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                              <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                              </svg>
                            </div>
                            <input
                              type="email"
                              id="email"
                              className="block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Introduce tu email"
                              required
                            />
                            <button
                              type="submit"
                              className="w-auto flex items-center justify-center absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-sky-500 rounded-r-lg hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800"
                            >
                              Suscríbete
                            </button>
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </section>
            </div >
          </div >

          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full mt-10 px-4 mx-auto text-center">
              <div className="text-xs text-blueGray-500 py-1">
                NadaQueLeer.com es participante en el Programa de Afiliados de Amazon EU, un programa de publicidad diseñado para ofrecer a sitios web una forma de obtener comisiones por publicidad al promocionar y enlazar a amazon.es.
                <br />
                <br />
                En calidad de Afiliado de Amazon, el propietario de NadaQueLeer.com genera ingresos por las compras que cumplan con los requisitos aplicables y sean realizadas a través de nuestros enlaces. Queremos enfatizar que este sistema de publicidad no implica ningún costo adicional para ti. Comprar productos a través de nuestros enlaces no afectará en absoluto los precios que encuentres en Amazon. Al hacerlo, nos brindas un valioso apoyo que contribuye al mantenimiento y mejora de este sitio web, y por ello estamos sinceramente agradecidos.
                <br />
                <br />
                Amazon y su logotipo son marcas comerciales registradas de Amazon.com, Inc. o sus afiliados.
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center text-blueGray-500 font-semibold">
              <div className="text-sm py-1">
                Copyright © {new Date().getFullYear()}{" "}
                <a
                  href="https://www.nadaqueleer.com"
                  className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
                >
                  NadaQueLeer.com
                </a>
              </div>
              Hecho con 💗 & 📚
            </div>
          </div>
        </div >
      </footer >
    </>
  );
}
