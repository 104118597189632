import React, { useState, useEffect } from "react";
import NewsletterService from "services/newsletter.service";
import booksService from "services/books.service";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

import PodcastDb from 'assets/podcasts/podcasts.json';
import BookList from "components/Book/BookList.js";
import PodcastList from "components/Podcast/PodcastList.js";

export default function Landing({ pageName }) {

  const [filteredBooks, setFilteredBooks] = useState([]);
  const [filteredPodcasts, setFilteredPodcasts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [bookMode, setBookMode] = useState(false);
  const [init, setInit] = useState(false);
  const [bookListTitle, setBookListTitle] = useState("📚 Libros añadidos recientemente");
  const [bookDb, setBookDb] = useState([]);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setBookMode(pageName === "libros");
    booksService.getBooks()
      .then((response) => {
        if (response.status === 200) {
          setBookDb(response.data);
          setInit(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (init) {
      console.log("useEffect")
      if (searchTerm !== "") {
        setBookMode(true);
        const filteredBooks = bookDb
          .filter(item => {
            const title = item.title.toLowerCase();
            const author = item.author.toLowerCase();
            const podcast = item.podcast.toLowerCase();
            const search = searchTerm.toLowerCase();

            return title.includes(search) || author.includes(search) || podcast.includes(search);
          })
          .sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA;
          });

        const numResults = filteredBooks.length;

        if (numResults === 0) {
          setBookListTitle(`No hemos podido encontrar nada para "${searchTerm}" 😢`);
        } else {
          setBookListTitle(`${numResults} ${numResults === 1 ? 'resultado' : 'resultados'} para "${searchTerm}"`);
          setFilteredBooks(filteredBooks);
        }
      } else {
        const filteredAndSortedBooks = bookDb
          .filter(item => item.isbn !== "" || item.isbn13 !== "")
          .sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA;
          });

        if (bookMode) {
          setFilteredBooks(filteredAndSortedBooks);
          setBookListTitle("");
        } else {
          setFilteredBooks(filteredAndSortedBooks.slice(0, 12));
          setBookListTitle("📚 Libros añadidos recientemente");

          const filteredAndSortedPodcasts = PodcastDb.map(podcast => {
            const latestBookDate = bookDb.reduce((latestDate, book) => {
              if (book.podcastId === podcast.id) {
                const bookDate = new Date(book.date);
                return bookDate > latestDate ? bookDate : latestDate;
              }
              return latestDate;
            }, new Date(0)); // Initialize with a minimum date

            return { ...podcast, latestBookDate };
          })
            .sort((a, b) => {
              const dateA = new Date(a.latestBookDate);
              const dateB = new Date(b.latestBookDate);
              return dateB - dateA;
            });

          setFilteredPodcasts(filteredAndSortedPodcasts);
        }
      }
    }
  }, [searchTerm, init]);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Access the input value by name
    const inputValue = e.target.elements['default-search'].value;
    setSearchTerm(inputValue);
  };

  const handleSub = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    // Access the input value by name
    const email = e.target.elements['email'].value;
    NewsletterService.newSub(email)
      .then(response => {
        const message = response.data.message;
        setSuccess(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-25">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-sky-300"
          >
          </div>

          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full px-4 pt-16 ml-auto mr-auto text-center">
                <h1 className="text-white font-semibold text-5xl">
                  Disfruta de los libros recomendados en tu podcast favorito
                </h1>
                <p className="mt-10 text-xl text-white">
                  {
                    //Nada Que Ganar, Kaizen, Heavy Mental y muchos más
                  }
                </p>

                {!bookMode && filteredPodcasts.length > 0 && (
                  <section className="pt-20">
                    <div className="flex flex-col items-center mb-16">
                      <h2 className="text-4xl font-semibold text-center text-white">
                        🎧 Podcasts más recientes
                      </h2>
                      {false &&
                        <a href="/podcasts" className="mt-4 inline-flex items-center font-medium text-base text-sky-600 hover:text-sky-800 dark:text-sky-500 dark:hover:text-sky-700">
                          VER TODOS
                          <svg className="ml-1 w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        </a>
                      }
                    </div>
                    <PodcastList podcasts={filteredPodcasts} />
                  </section>
                )}

                {true &&
                  <section className="">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                      <div className="mx-auto max-w-screen-md sm:text-center">
                        <h2 className="mb-4 text-3xl tracking-tight font-semibold text-white sm:text-4xl dark:text-white">
                          Recibe los mejores libros en tu bandeja de entrada
                        </h2>
                        <p className="mx-auto mb-8 max-w-2xl font-light text-white md:mb-12 sm:text-xl dark:text-gray-400">
                          No te pierdas ninguna recomendación con nuestro newsletter semanal.
                        </p>
                        <form onSubmit={handleSub}>
                          <label htmlFor="email" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Newsletter</label>
                          <div className="relative">
                            {success ? (
                              <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500">
                                <span className="text-xl inline-block mr-5 align-middle">
                                  <i className="fas fa-check"></i>
                                </span>
                                <span className="inline-block align-middle mr-8">
                                  <b className="capitalize">Comprueba tu bandeja de entrada y confirma tu suscripción</b>
                                </span>
                              </div>
                            ) : (
                              <>
                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                  <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                  </svg>
                                </div>
                                <input
                                  type="email"
                                  id="email"
                                  className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Introduce tu email"
                                  required
                                />
                                <button
                                  type="submit"
                                  className="w-auto flex items-center justify-center absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-sky-500 rounded-r-lg hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800"
                                >
                                  Suscríbete
                                </button>
                              </>
                            )
                            }
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                }
                {false &&
                  <form onSubmit={handleSubmit} className="mt-20">
                    <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                    <div className="relative">
                      <input
                        type="search"
                        id="default-search"
                        className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Encuentra por título, autor, podcast..."
                        required
                      />
                      <button
                        type="submit"
                        className="w-12 flex items-center justify-center absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-sky-500 rounded-r-lg hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800"
                      >
                        <svg
                          className="w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                        <span className="sr-only">Search</span>
                      </button>

                    </div>
                  </form>
                }
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        {filteredBooks.length > 0 && (
          <section className="pt-20 pb-48">
            <div className="flex flex-col items-center mb-16">
              <h2 className="text-4xl font-semibold text-center">
                {bookListTitle}
              </h2>
              {!bookMode &&
                <a href="/libros" className="mt-4 inline-flex items-center font-medium text-base text-sky-600 hover:text-sky-800 dark:text-sky-500 dark:hover:text-sky-700">
                  VER TODOS
                  <svg className="ml-1 w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                </a>
              }
            </div>
            <BookList books={filteredBooks} />
            {!bookMode &&
              <div className="flex justify-center">
                <a
                  type="button"
                  href="/libros"
                  className="text-white text-lg bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-6 py-3.5 text-center">
                  MÁS LIBROS
                </a>
              </div>
            }

          </section>
        )}

      </main>
      <Footer />
    </>
  );
}