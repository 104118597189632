import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

import Book from "components/Book/Book.js";

export default function BookList({ books }) {
    const booksPerPage = 12;

    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedBooks, setPaginatedBooks] = useState([]);
    const [key, setKey] = useState(uuidv4()); // Add a key that changes when books change

    useEffect(() => {
        // Calculate the start and end index for the current page
        const startIndex = (currentPage - 1) * booksPerPage;
        const endIndex = startIndex + booksPerPage;

        // Get the books for the current page
        const currentBooks = books.slice(startIndex, endIndex);

        // Set the paginated books for rendering
        setPaginatedBooks(currentBooks);
    }, [books, currentPage]);

    useEffect(() => {
        // Update the key whenever books change to reset the component
        setKey(uuidv4());
    }, [paginatedBooks]);

    const totalPages = Math.ceil(books.length / booksPerPage);

    // Calculate start and end page numbers
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
                {paginatedBooks.map((book) => (
                    <Book key={uuidv4()} bookInfo={book} />
                ))}
            </div>

            {totalPages > 1 &&
                <div className="mt-4">
                    <nav aria-label="Page navigation example">
                        <ul className="flex items-center justify-center -space-x-px h-10 text-base">
                            <li>
                                <a
                                    href="#"
                                    className="flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    <span className="sr-only">Previous</span>
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 6 10"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 1 1 5l4 4"
                                        />
                                    </svg>
                                </a>
                            </li>
                            {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
                                <li key={startPage + i}>
                                    <a
                                        href="#"
                                        className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 ${startPage + i === currentPage
                                                ? "font-bold text-sky-700 bg-sky-100 border-sky-400 hover:text-sky-900 dark:border-gray-700 dark:bg-sky-700 dark:text-white"
                                                : "hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                            }`}
                                        onClick={() => handlePageChange(startPage + i)}
                                    >
                                        {startPage + i}
                                    </a>
                                </li>
                            ))}
                            <li>
                                <a
                                    href="#"
                                    className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    <span className="sr-only">Next</span>
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 6 10"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 9 4-4-4-4"
                                        />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            }
        </div>
    );
}