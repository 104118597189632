import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

// views without layouts

import Landing from "views/Landing.js";
import Newsletter from "views/Newsletter";
import Terms from "views/Terms";
import Privacy from "views/Privacy";
import PodcastPage from "views/PodcastPage";


function App() {

    return (
        <Routes>
            <Route path="/" element={<Landing pageName={''} />} />
            <Route path="/libros" element={<Landing pageName={'libros'} />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/newsletter" element={<Newsletter />} />
            <Route path="/:podcastId" element={<PodcastPage />} />
        </Routes>
    );
}

export default App;