import React, { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from 'uuid';

// components

import Podcast from "./Podcast";

export default function PodcastList({podcasts}) {

    const initialPodcastsToShow = 12; // Number of Podcasts to initially load
    const podcastsToLoadOnScroll = 12; // Number of Podcasts to load on each scroll

    const [filteredPodcasts, setFilteredPodcasts] = useState(podcasts);
    const [visiblePodcasts, setVisiblePodcasts] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        console.log(filteredPodcasts);
        setVisiblePodcasts(filteredPodcasts.slice(0, initialPodcastsToShow));
        setLoaded(true);
    }, [filteredPodcasts]);

    // Load more posts when the user scrolls to the bottom
    const handleScroll = useCallback(() => {
        if (document.documentElement.scrollHeight <=
            window.innerHeight + document.documentElement.scrollTop + 1
        ) {
            const visibleCount = visiblePodcasts.length;
            const remainingPodcasts = filteredPodcasts.slice(visibleCount);

            if (remainingPodcasts.length > 0) {
                const nextBatch = remainingPodcasts.slice(0, podcastsToLoadOnScroll);
                setVisiblePodcasts(prevVisiblePodcasts => [...prevVisiblePodcasts, ...nextBatch]);
            }
        }
    }, [filteredPodcasts, visiblePodcasts]);

    useEffect(() => {
        if (loaded) {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [loaded, handleScroll]);

    return (
        <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center">
                {visiblePodcasts.map((podcast) => (
                    <Podcast key={uuidv4()} podcastInfo={podcast} />
                ))}
            </div>
        </div>
    );

}